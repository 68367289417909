<template>
    <div>
        <form-data-client :client="client" :disabled="true"/>
        <b-row>
            <b-col sm="6">
                <b-form-group label="Direcció fiscal" label-for="account-surnames">
                    <b-form-input v-model="client.client_personal_data.fiscal_address" placeholder="Direcció fiscal"
                                  :class="validation_extra_data_client.fiscal_address ? 'is-invalid': ''"/>
                    <small v-if="validation_extra_data_client.fiscal_address" class="text-danger">
                        {{ validation_extra_data_client.fiscal_address }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="2">
                <b-form-group label="Codi postal fiscal" label-for="account-surnames">
                    <b-form-input v-model="client.client_personal_data.fiscal_code" placeholder="Codi postal fiscal"
                                  :class="validation_extra_data_client.fiscal_code ? 'is-invalid': ''"/>
                    <small v-if="validation_extra_data_client.fiscal_code" class="text-danger">
                        {{ validation_extra_data_client.fiscal_code }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="2">
                <b-form-group label="Localitat" label-for="account-surnames">
                    <b-form-input v-model="client.client_personal_data.location" placeholder="Localitat"
                                  :class="validation_extra_data_client.location ? 'is-invalid': ''"/>
                    <small v-if="validation_extra_data_client.location" class="text-danger">
                        {{ validation_extra_data_client.location }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="2">
                <b-form-group label="Provincia" label-for="account-surnames">
                    <b-form-input v-model="client.client_personal_data.province" placeholder="Provincia"
                                  :class="validation_extra_data_client.province ? 'is-invalid': ''"/>
                    <small v-if="validation_extra_data_client.province" class="text-danger">
                        {{ validation_extra_data_client.province }}
                    </small>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="DNI" label-for="dni_cif">
                    <b-form-input v-model="client.client_personal_data.dni" placeholder="DNI"
                                  :class="validation_extra_data_client.dni ? 'is-invalid': ''"/>
                    <small v-if="validation_extra_data_client.dni" class="text-danger">
                        {{ validation_extra_data_client.dni }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Data de naixement" label-for="birthday">
                    <flat-pickr
                        v-model="client.client_personal_data.birthday"
                        class="form-control"
                        placeholder="Data de naixement"
                        :config="{ enableTime: false, locale: CatalanLocale, dateFormat: 'd-m-Y'}"
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="currentRouteName !== 'clients_edit'" align-h="start">
            <b-col cols="5">
                <b-button variant="primary" class="mt-2 mr-1" @click="updateData">
                    Actualitzar client
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { BCol, BFormGroup, BFormInput, BRow, BButton} from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"
import {mapState} from "vuex"
import FormDataClient from "@/views/clients/form/formDataClientView"
import ToastificationContent from "@core/components/toastification/ToastificationContent"
import flatPickr from "vue-flatpickr-component"
import {Catalan as CatalanLocale} from 'flatpickr/dist/l10n/cat'

const {mapFields} = createHelpers({
    getterType: 'client/getField',
    mutationType: 'client/updateField',
})

export default {
    name: "FormClientPersonalData",
    components: {
        BCol,
        BRow,
        BFormGroup,
        BFormInput,
        BButton,
      
        FormDataClient,
        flatPickr
    },
    data() {
        return {
            CatalanLocale,
        }
    },
    computed: {
        ...mapState('client', ['validation_extra_data_client']),
        ...mapFields({
            client: 'client',
            client_personal_data: 'client_personal_data'
        }),
        currentRouteName() {
            return this.$route.name
        }
    },
    methods:{
        updateData() {
            this.client.client_personal_data.client_id = this.client.id

            this.$store.dispatch('client/updateClient', this.client)
            .then(() => {
                this.showLoading = false
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: `Client actualitzat correctament!`,
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                })
            })
            .catch(error => {
                console.log(error)
            })
        },
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>