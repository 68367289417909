<template>
    <div>
        <b-card class="p-1 p-lg-0">
            <b-tabs align="left">
                <b-tab active title="Informació general" lazy>
                    <b-overlay
                        variant="white"
                        :show="showLoading"
                        spinner-variant="primary"
                        blur="0"
                        opacity=".75"
                        rounded="sm"
                    >
                        <b-row>
                            <b-col sm="6">
                                <b-form-group label="Client" label-for="value">
                                    <v-select
                                        v-model="formality.client_id"
                                        :options="clients"
                                        label="name"
                                        :reduce="(option) => option.id"
                                        :disabled="this.edit"
                                    >
                                        <template slot="option" slot-scope="option">
                                            {{ option.name }} {{ option.first_surname }} {{ option.second_surname }}
                                        </template>
                                        <template slot="selected-option" slot-scope="option">
                                            {{ option.name }} {{ option.first_surname }} {{ option.second_surname }}
                                        </template>
                                        <template #no-options="{ }">
                                            No s'han pogut carregar els clients
                                        </template>
                                    </v-select>
                                    <small v-if="validation_formality.client_id" class="text-danger">
                                        {{ validation_formality.client_id }}
                                    </small>

                                </b-form-group>
                            </b-col>
                            <b-col lg="6" md="12" sm="12">
                                <b-form-group label="Tipus" label-for="value">
                                    <v-select
                                        v-model="formality.type"
                                        :options="formalityTypes"
                                        label="name"
                                        :reduce="(option) => option.value"
                                    />
                                    <small v-if="validation_formality.insurance_type" class="text-danger">
                                        {{ validation_formality.insurance_type }}
                                    </small>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-col class="row col-sm-12">
                            <b-button v-if="!edit" variant="primary" class="mt-2 mr-1" @click="saveData">
                                Desar Canvis
                            </b-button>
                            <b-button v-else variant="primary" class="mt-2 mr-1" @click="saveData">
                                Actualitzar Canvis
                            </b-button>
                            <b-button variant="outline-secondary" type="reset" class="mt-2" @click="cancel">
                                Cancel·lar
                            </b-button>
                        </b-col>
                    </b-overlay>
                </b-tab>
                <b-tab v-if="formality.id" title="Dades personals" lazy>
                    <form-data-address-client/>
                </b-tab>
                <b-tab v-if="formality.id" title="Documents" lazy>
                    <form-documents ref="formDocuments"></form-documents>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import {BButton, BCard, BCol, BFormGroup, BTab, BTabs, BOverlay, BRow} from 'bootstrap-vue'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import vSelect from "vue-select"
import {FORMALITIES_LIST} from "@/router/web/constants"
import ToastificationContent from "@core/components/toastification/ToastificationContent"
import {formalityTypes} from "@/constants/laborFormalities"

import FormDocuments from "@/views/labor/formalities/form/components/formDocumentsView"

import FormDataAddressClient from "@/views/clients/form/formClientPersonalDataView"

import {createHelpers} from "vuex-map-fields"
import {mapState} from "vuex"

const {mapFields} = createHelpers({
    getterType: 'formalities/getField',
    mutationType: 'formalities/updateField',
})


export default {
    name: "FormalityFormView",
    components: {
        BCol,
        BRow,
        BButton,
        BFormGroup,
        BCard,
        BOverlay,
        BTab,
        BTabs,

        vSelect,
        FormDocuments,
        FormDataAddressClient
    },
    data() {
        return {
            users: [],
            clients: [],
            edit: false,
            showLoading: false,
            SpanishLocale,

            formalityTypes
        }
    },
    computed: {
        ...mapState('formalities', ['validation_formality']),
        ...mapFields({
            formality: 'formality',
            formality_documents: 'formality.formality_documents',
        }),
    },
    created() {
        this.$store.commit('formalities/RESET_STATE')
        this.getClients()
        if (this.$route.params.id) {
            this.edit = true
            this.getFormality()
        } else {
            this.edit = false
        }
    },
    methods: {
        getFormality() {
            this.showLoading = true
            this.$store.dispatch('formalities/getFormality', {
                id: this.$route.params.id ?? this.formality.id,
                relations: ['client', 'client.clientPersonalData',
                     'formalityDocuments'],
            })
            .then(response => {
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
        getClients() {
            this.showLoading = true

            this.$store.dispatch('client/getAllClients', {
                id: this.$route.params.id,
                relations: ['responsible'],
            })
            .then(response => {
                this.clients = response
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
        formatData() {
            var formData = new FormData()
            this.appendFormData(formData, this.formality, 'formality')
            return formData
        },
        appendFormData(formData, data, rootName) {
            let root = rootName || ''
            if (data instanceof File) {
                formData.append(root, data)
            } else if (Array.isArray(data)) {
                for (var i = 0; i < data.length; i++) {
                    this.appendFormData(formData, data[i], root + '[' + i + ']')
                }
            } else if (typeof data === 'object' && data) {
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === '') {
                            this.appendFormData(formData, data[key], key)
                        } else {
                            this.appendFormData(formData, data[key], `${root}[${key}]`)
                        }
                    }
                }
            } else {
                if (data !== null && typeof data !== 'undefined') {
                    formData.append(root, data)
                } else {
                    formData.append(root, '')
                }
            }
        },
        saveData() {
            if (!this.formality.id) {
                this.$store.dispatch('formalities/createFormality', this.formatData())
                .then(response => {

                    this.formality.id = response.id
                    this.edit = true
                    this.showToast('Tràmit creat correctament!', 'CheckIcon', 'success')
                    this.getFormality()
                })
                .catch(error => {
                    this.showToast('Error en crear la pòlissa!', 'AlertOctagonIcon', 'danger')
                })
            } else {
                this.$store.dispatch('formalities/updateFormality', this.formatData())
                .then(response => {
                    this.showToast('Dades actualitzades correctament!', 'CheckIcon', 'success')
                    this.getFormality()
                  //  this.formality_data.contract_house = response.formality_data.contract_house
                })
                .catch(error => {
                    this.showToast('Error en editar la pòlissa!', 'AlertOctagonIcon', 'danger')
                })
            }
        },
        cancel() {
            this.$router.push(FORMALITIES_LIST)
        },
        showToast(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: title,
                    icon: icon,
                    variant: variant,
                },
            })
        },
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>